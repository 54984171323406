<template>
  <module
      ref="module"
      id="withdrawals"
      titleIcon="fa fa-forward"
      :title="$t('withdrawals_request')"
      :use-default-list="false"
  >
    <div slot="global">
      <!-- In progress -->
      <div v-if="inprogress" class="text-center">
        <h2>{{ inprogresstxt }}</h2>
        <i class="fa fa-cog fa-spin fa-huge fa-fw" style="color: #51bcda;"></i>
      </div>

      <div v-else class="row">
        <!-- Modal for Confirm Removal -->
        <modal :show.sync="modalconfirm" headerClasses="justify-content-center">
          <h4 slot="header" class="title title-up">{{ $t('pleaseconfirm_alt') }}</h4>

          <div class="row">
            <div class="col-12">
              {{ confirm_msg }}
            </div>
          </div>

          <template slot="footer">
            <div class="left-side">
              <p-button @click.prevent="confirmed" link>{{ $t('yestxt') }}</p-button>
            </div>
            <div class="divider"></div>
            <div class="right-side">
              <p-button type="danger" v-on:click="modalconfirm = false" link>{{ $t('notxt') }}</p-button>
            </div>
          </template>
        </modal>
        <!-- End Modal -->

        <!-- Modal for Description -->
        <modal :show.sync="modaldescription" headerClasses="justify-content-center">
          <h4 slot="header" class="title title-up">{{ $t('description') }}</h4>

          <div class="row">
            <div class="col-12">
              {{ description }}
            </div>
          </div>

          <template slot="footer">
            <p-button type="info" @click.prevent="modaldescription = false" link>{{ $t('gotit') }}</p-button>
          </template>
        </modal>
        <!-- End Modal -->

        <div class="col-12 text-center" v-if="alert_me">
          <div :class="alert_class" class="ml-2 mb-2" style="display: block;font-weight: bold;">
            {{ alert_msg }}
          </div>
        </div>

        <!-- Table Withdrawal requests -->
        <div class="col-md-12">
          <table-withdrawal :tableData="tableWithdrawals"
                            :sortby="sortbywith" :pagination="paginationwith"
                            :propsToSearch="propsToSearchwith" :tableColumns="tableColumnswith"
                            :clonedTableData="clonedWithdrawalTableData" :adminusr="adminusr"
                            @on-cancel-with="cancelWithdrawal" @on-approve-with="approveWithdrawal"
                            @on-show-desc="showDescription">
          </table-withdrawal>
        </div>

      </div>
    </div>
  </module>

</template>
<script>
import Vue from 'vue'
import {Button, Modal} from 'src/components/UIComponents'
import TableWithdrawal from 'src/components/Dashboard/Views/Components/TableWithdrawal'

Vue.use(require('vue-moment'))

const user_data = JSON.parse(localStorage.getItem('user-info'));

let timeoutcount = 1500;

export default {
  components: {
    [Button.name]: Button, Modal, TableWithdrawal
  },
  data() {
    return {
      inprogress: true,
      actionlbl: this.$t('action'),
      alert_me: false, alert_msg: null,
      alert_class: 'text-danger',
      modalup: false,
      alert_me_modal: false,
      confirm_msg: null,
      modalconfirm: false,
      cancelwith: false,
      undowith: false,
      tranid: null,
      inprogresstxt: this.$t('loadingtxt') + " " + this.$t('withdrawals') + ". " + this.$t('pleasewait'),
      sortbywith: {
        order: 'desc',
        orders: [
          {prop: 'asc', label: this.$t('ascending')},
          {prop: 'desc', label: this.$t('descending')}
        ],
        column: 'request_dt',
        columns: [
          {prop: 'userid', label: this.$t('userid')},
          {prop: 'request_dt', label: this.$t('requestedon')},
          {prop: 'amount_requested', label: this.$t('withdrawal_requested_amt')},
          {prop: 'currency', label: this.$t('currency')},
          {prop: 'payment_method', label: this.$t('paymentmethod')},
          {prop: 'decision_dt', label: this.$t('decidedon')},
          {prop: 'amount_to_paid', label: this.$t('withdrawal_fund_amt')},
          {prop: 'approval_state', label: this.$t('status')},
          {prop: 'wallet_balance', label: this.$t('balance')},
          {prop: 'withdrawal_limit', label: this.$t('limittxt')}
        ]
      },
      paginationwith: {
        perPage: 50,
        currentPage: 1,
        perPageOptions: [5, 10, 25, 50],
        total: 0
      }
      ,
      searchQuerywith: '',
      propsToSearchwith: ['userid', 'tran_id', 'currency', 'payment_method', 'approval_state'],
      tableColumnswith: [
        //{ prop: 'tran_id', label: this.$t('tranid') },
        {prop: 'userid', label: this.$t('userid')},
        {prop: 'request_dt', label: this.$t('requestedon')},
        {prop: 'amount_requested', label: this.$t('withdrawal_requested_amt')},
        {prop: 'currency', label: this.$t('currency')},
        {prop: 'payment_method', label: this.$t('paymentmethod')},
        {prop: 'decision_dt', label: this.$t('decidedon')},
        {prop: 'amount_to_paid', label: this.$t('withdrawal_fund_amt')},
        {prop: 'approval_state', label: this.$t('status')},
        {prop: 'wallet_balance', label: this.$t('balance')},
        {prop: 'withdrawal_limit', label: this.$t('limittxt')}
      ],
      tableWithdrawals: [],
      clonedWithdrawalTableData: [],
      modaldescription: false,
      description: null,
      withdrawData: [],
      model: {},
    }
  },
  methods: {
    showDescription(description_txt) {
      this.description = description_txt;
      this.modaldescription = true;
    },
    cancelWithdrawal(tid, undo) {
      this.confirm_msg = this.$t('withdrawal_cancel');
      this.cancelwith = true;
      this.undowith = false;
      this.tranid = tid;

      if (undo) {
        this.confirm_msg = this.$t('withdrawal_undo_cancel');
        this.undowith = true;
      }
      this.modalconfirm = true;
    },
    loadWithdraw(resp) {
      this.withdrawData = resp.data[0]
      this.inprogress = false
    },
    approveWithdrawal(tid, undo) {
      this.inprogress = true
      this.confirm_msg = this.$t('withdrawal_approve');
      this.cancelwith = false;
      this.undowith = false;
      this.tranid = tid;
      this.$getUserWithdrawalByTranId(this.tranid).then(this.loadWithdraw)
      if (undo) {
        this.confirm_msg = this.$t('withdrawal_undo_approve');
        this.undowith = true;
      }
      this.modalconfirm = true;
    },
    confirmed() {
      // Undo Rejection
      if (this.undowith) {
        this.$opWithdrawRequest(this.ueml, this.tranid, 'undo')
            .then(this.closeModal, this.failWith);
      } else if (this.cancelwith) {
        // Reject Withdrawal
        this.$opWithdrawRequest(this.ueml, this.tranid, 'reject')
            .then(this.closeModal, this.failWith);
      } else {
        // Approve Withdrawal
        if (this.withdrawData.payment_method.toLowerCase() === 'new_pix' || this.withdrawData.payment_method.toLowerCase() === 'new pix') {
          this.createOrder()
        } else {
          this.$opWithdrawRequest(this.ueml, this.tranid, 'approve')
              .then(this.closeModal, this.failWith);
        }
      }
    },
    async createOrder() {
      this.closeModal()
      this.loading = true;
      this.$toast.success(this.$t('pw_order_created_txt'));
      this.model = {
        method_id: 3,
        type: 'withdraw',
        userid: this.ueml,
        value: this.withdrawData.amount_requested,
        integration_data: {
          pix: this.withdrawData.description,
          tran_id: this.tranid
        }
      }
      const response = await this.$createWalletOrder({
        ...this.model,
        integration_data: JSON.stringify(this.model.integration_data),
      }).then(response => {
        if (!this.$validateResponse(response)) {
          return;
        }
        this.model.order = response.data;
        this.$toast.success(this.$t(response.msg));
        this.loading = false
        this.tranid = null
        setTimeout(() => {
          window.location.reload();
        }, 15000);
      }).catch(err => {
        this.$validateResponse(err.data);
        this.loading = false;
      });
    },
    loadPendings(resp) {
      if (!resp.success) {
        this.$toast.error(this.$t(resp.msg));
        return;
      }
      this.tableWithdrawals = resp.data
      this.inprogress = false;
    },
    getWithdrawals() {
      this.tableWithdrawals = [];
      this.$getUserWithdrawal()
          .then(this.loadPendings, this.failop);
    },
    closeModal() {
      // this.$toast.success(this.$t(resp.msg));
      this.modalconfirm = false;
      this.cancelwith = false;
      this.undowith = false;
      this.inprogress = true;
      this.getWithdrawals();
    }
  },
  mounted() {
    // Admin User or NOT
    if (user_data['roles'].indexOf('admin') >= 0) {
      this.adminusr = true;
    }
    this.ueml = user_data.email;

    this.getWithdrawals();
  },
  created() {
    this.$setTranslatingInProgress()
  },
  beforeDestroy() {
    this.$root.$off("showTranslatingProgress")
  },
}
</script>
<style>
.auto-ul {
  list-style: none;
  padding: 5px;
  overflow: scroll;
  height: 100px;
  position: absolute;
  background-color: white;
  z-index: 9999;
  border: 1px solid silver;
  width: 93%;
}

.auto-ul li:hover {
  background: silver;
  cursor: pointer;
}
</style>
